<!-- 인벤토리 수정/등록 -->
<template>
  <v-container
    id="inventoryRegister"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
      <base-material-card
              color="green">
        <template v-slot:heading>
          <h1 class="display-2 font-weight-regular">
            <span v-if="response.media">
              {{ response.media.kor_name }} >
            </span>
            인벤토리 {{resultInventory}}
          </h1>
      </template>
        <v-card-text>
          <h2 style="padding: 20px 10px;">
            인벤토리 정보 {{resultInventory}}
          </h2>
          <v-form
                  v-model="vModel.valid"
                  ref="form">
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                인벤토리명
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="인벤토리명"
                        type="text"
                        name="title"
                        color="secondary"
                        v-model="vModel.data.title"
                        :rules="config.titleRules"
                        required />
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                컨텐츠 소지 갯수
              </v-col>
              <v-col cols="5">
                <v-text-field
                        placeholder="컨텐츠 소지 갯수"
                        type="number"
                        name="limit"
                        color="secondary"
                        v-model="vModel.data.limit"
                        :rules="config.limitRules"
                        required />
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5"
                    align="center">
              <v-col cols="1" class="text-right grey--text">
                CP 선택
              </v-col>
              <v-col cols="5">
                <v-select
                        :items="config.linkPartners"
                        v-model="vModel.data.partner_id"
                        name="partners"
                        multiple
                        placeholder="CP를 선택하세요."
                        color="secondary"
                        @change="partnersChange"/>
              </v-col>
              <v-col cols="1" class="text-right grey--text">
                정산 API uri
              </v-col>
              <v-col cols="5">
                http://api.aiinfeed.com/publisher/report/{{ response.media.placement_id }}/revenue/{{ vModel.data.id }}?key={{ response.media.hash_key }}&startDate={start_date}&endDate={endDate}
                <br><br>
                <div>{startDate}, {endDate} format : 2020-07-01</div>
              </v-col>
            </v-row>
            <v-row class="pl-5 pr-5">
              <v-col cols="1" class="text-right grey--text">
                카테고리 선택
              </v-col>
              <v-col>
                <template v-if="config.acceptCategories.length > 0">
                  <div
                          class="d-inline-flex"
                          style="width:18%;margin-right:2%;"
                          v-for="val in config.acceptCategories"
                          :key="val.header.id">
                    <v-data-table
                            style="width:100%"
                            :headers="val.header"
                            :items="val.selections"
                            :items-per-page="Number(val.selections.length)"
                            hide-default-footer
                            show-select
                            v-model="acceptCategoriesData">
                      <template v-slot:item.name="{ item }">
                        {{ item.name }} ({{item.contents_count}})
                      </template>
                    </v-data-table>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-5">
              <v-btn
                      class="mt-5"
                      color="default"
                      @click="beforePage">
                취소
              </v-btn>
              <template v-if="['mediaInventoryRegister'].includes($route.name)">
                <v-btn
                    :disabled="!vModel.valid"
                    class="mt-5"
                    color="success"
                    @click="submitRegister">
                    등록
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                        :disabled="!vModel.valid"
                        class="mt-5"
                        color="success"
                        @click="submitEdit">
                  수정
                </v-btn>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
      </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        media: null,
        partner: null,
        category: null,
        inventory: null,
        register: null,
        edit: null,
      },
      vModel: {
        valid: true,
        autoBool: true,
        data: {
          title: null,
          limit: null,
          collection_order: 1,
          auto_collection: null,
          partner_id: null,
          category_id: [],
        },
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      acceptCategoriesData: [],
      config: {
        titleRules: [ v => !!v || '이름을 입력하세요.' ],
        limitRules: [ v => !!v || '숫자를 입력하세요.' ],
        linkPartners: null,
        acceptCategories: []
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      this.getMedia()
    },
    /* cp 선택시 */
    partnersChange () {
      this.postCategories()
    },
    /* 카테고리 리스트 가져오기 */
    postCategories () {
      let send = {
        partner: this.vModel.data.partner_id
      }
      this.xhttp({
        url: '/categories/show',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.category = response.data.data.categories
          this.categorySet()
          this.categoryInventory()
          if (['mediaInventoryEdit'].includes(this.$route.name)) {
            this.categoryChecked()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 카테고리 테이블에 넣을 값 만들기 */
    categorySet () {
      this.config.acceptCategories = []
      for (let i = 0; i < this.response.category.length; i++) {
        let category = {}
        /* header 에 종류 넣기 */
        category.header = [this.response.category[i]]
        category.header[0].value = 'name'
        category.header[0].text = category.header[0].name
        let children = []
        for (let j = 0; j < this.response.category[i].children.length; j++) {
          /* 컨텐츠가 있는 카테고리만 */
          if (this.response.category[i].children[j].contents_count > 0) {
            children.push(this.response.category[i].children[j])
          }
        }
        category.selections = children
        this.config.acceptCategories.push(category)
      }
    },
    /* 해당 인벤토리의 매체가 선택한 카테고리값만 나오게 */
    categoryInventory () {
      let arr = this.response.media.acceptCategories
      let arrBool = [true, true]
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < this.response.category.length; j++) {
          let result = this.response.category[j].children.filter(function (n) {
            return n.id === arr[i]
          })
          if (result.length > 0) {
            if (arrBool[i]) {
              this.config.acceptCategories[j].selections = []
              arrBool = false
            }
            this.config.acceptCategories[j].selections.push(result[0])
          }
        }
      }
    },
    /* 저장된 카테고리값 체크하기 */
    categoryChecked () {
      let arr = this.response.inventory.approved_categories
      let result = []
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < this.response.category.length; j++) {
          let adult = this.response.category[j].children.filter(function (n) {
            return n.id === arr[i].id
          })
          if (adult.length > 0) {
            result.push(adult[0])
          }
        }
      }
      this.acceptCategoriesData = result
    },
    /* 매체 상세정보 가져오기 */
    getMedia () {
      let media = this.$route.params.media_id
      this.xhttp({
        url: '/media/' + media,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.linkPartners = null
          if (this.response.media.linkPartner.length > 0) {
            this.config.linkPartners = this.response.media.linkPartner.map(function (val) {
              if (val.partner_approved === 1) {
                return { value: val.partner_id, text: val.partner.name }
              }
            })
          }
          if (['mediaInventoryEdit'].includes(this.$route.name)) {
            this.getInventory()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 인벤토리 상세정보 가져오기 */
    getInventory () {
      let media = this.$route.params.media_id
      this.xhttp({
        url: '/' + media + '/inventory/' + this.$route.params.inventory_id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.inventory = response.data.data
          this.vModel.data = this.response.inventory
          if (this.response.inventory.auto_collection === 1) {
            this.vModel.autoBool = true
          } else {
            this.vModel.autoBool = false
          }
          if (this.response.inventory.approved_partners.length > 0) {
            this.vModel.data.partner_id = this.response.inventory.approved_partners.map(function (val) {
              return val.id
            })
          }
          this.postCategories()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 인벤토리 등록하기 */
    submitRegister () {
      if (this.$refs.form.validate()) {
        if (confirm('등록 하겠습니까?')) {
          let send = this.vModel.data
          send.auto_collection = this.vModel.autoBool ? 1 : 0
          let media = this.$route.params.media_id
          this.xhttp({
            url: '/' + media + '/inventory',
            method: 'post',
            data: send
          }).then((response) => {
            if (response.status === 200) {
              this.response.register = response.data.data
              this.snack('success', '인벤토리 등록이 완료되었습니다.')
              this.beforePage()
            }
          }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
              this.$router.push({ 'name': 'UserLogin' })
            } else {
              this.snack('warning', '관리자에게 문의 바랍니다.')
            }
          })
        }
      }
    },
    /* 인벤토리 수정하기 */
    submitEdit () {
      if (this.$refs.form.validate()) {
        if (confirm('수정 하겠습니까?')) {
          let send = this.vModel.data
          send.auto_collection = this.vModel.autoBool ? 1 : 0
          let media = this.$route.params.media_id
          this.xhttp({
            url: '/' + media + '/inventory/' + this.$route.params.inventory_id,
            method: 'put',
            data: send
          }).then((response) => {
            if (response.status === 200) {
              this.response.edit = response.data.data
              this.snack('success', '인벤토리 정보 수정 완료되었습니다.')
              this.beforePage()
            }
          }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
              this.$router.push({ 'name': 'UserLogin' })
            } else {
              this.snack('warning', '관리자에게 문의 바랍니다.')
            }
          })
        }
      }
    },
  },
  computed: {
    resultInventory () {
      if (['mediaInventoryEdit'].includes(this.$route.name)) {
        return '수정'
      } else {
        return '등록'
      }
    },
  },
  watch: {
    acceptCategoriesData: function (val) {
      if (val) {
        this.vModel.data.category_id = val.map(function ($value) {
          return $value.id
        })
      }
    }
  }
}
</script>

<style>
  #inventoryRegister .v-data-table__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12)
  }

  #inventoryRegister .v-data-table-header {
  background: #ddd
  }
</style>
